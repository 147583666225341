:root {
  --primary: #000;
  --secondary: #3e4954;
  --primary-hover: #353232;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #000 !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background: #e9f5f6;
  color: var(--primary);
}
.bgl-primary {
  background: #e9f5f6;
  border-color: var(--rgba-primary-1);
}
.light.btn-info {
  background: #e9f5f6 !important;
  color: #000 !important;
}
.light.btn-info:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}

.light.btn-info g [fill] {
  fill: #000;
}
.light.btn-info:hover g [fill] {
  fill: #fff;
}
.btn-primary {
  font-weight: 600 !important;
}
.form-control {
  background-color: #e9f5f6;
  color: #000;
}
.form-control:hover {
  background-color: #e9f5f6 !important;
}
.search-box {
  background-color: #fff !important;
}
.details-profile {
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.bio-user {
  box-shadow: rgba(17, 17, 17, 0.16) 0px 0px 3px;
  border-radius: 5px 5px 5px 5px;
}
