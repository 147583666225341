.authincation {
	background: var(--rgba-primary-1);
    display: flex;
    min-height: 100vh;
}
.authincation-content{
    background: $white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
    @at-root [data-theme-version="dark"] & {
        background: $dark-card;
        box-shadow: none;
    }

}
.welcome-content{
    background: url('./../images/1.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 75px 50px;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $primary;
        opacity: 0.75;
        z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .welcome-title{
        color: $white;
        font-weight: 500;
        font-size: 20px;
    }
    p{
        color: $white;
    }

    .brand-logo{
        a{
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 700;
            color: $white;
            font-size: 20px;
        img{
            width: 100px;
        }
        }
    }

    .intro-social{
        position: absolute;
        bottom: 75px;
        ul{
            margin-bottom: 0;
            li{
                display: inline-block;
                a{
                    color: $white;
                    font-size: 14px;
                    padding: 0px 7px;
                }
            }
        }
    }
}
.auth-form{
    padding: 50px 50px;
	@include respond('phone') {
		padding: 30px;
	}	
    .btn{
        height: 50px;
        font-weight: 700;
    }
    .page-back{
        display: inline-block;
        margin-bottom: 15px;
    }
}

.login-form-bx{
	padding-bottom: 0;
	background-color: #fbfbfb;
	overflow: hidden;
	
	.form-control {
		border: 1px solid #d3d3d3;
	}
	.login-logo{
		margin-bottom: 30px;
		display: block;
	
		.logo-icon{
			width: 60px;
		}
		.logo-text{
			width: 120px;
		}
	}
	.inner-content{
		max-width: 500px;
		padding-left: 50px;
		padding-right: 30px;
	}
	.social-icons{
		li{
			display: inline-block;
			margin-right: 10px;
			a{
				display: block;
				background-color: #fff;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				border-radius: 4px;
				color: $primary;
			}
		}
	}
	.box-skew,
	.box-skew1{
		height:100vh;
		min-height:100vh;
		position:relative;
	}
	.box-skew1{
		z-index:1;
		background-color: var(--white);
		&:after{
			content: "";
			background-image: url(../images/bg-login.jpg);
			height: 100%;
			position: absolute;
			width: 120%;
			left: -30px;
			top: 0;
			background-size: cover;
			z-index: -1;
			transform: skew(-5deg);
		}
	}
	.box-skew{
		.authincation-content{
			box-shadow: none;
			position: absolute;
			z-index: 1;
			right: 50px;
			top: 50%;
			transform: translateY(-50%);
			padding: 50px 50px;
			width: 500px;
				
			&:after{
				box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.08);
				background-color:#fff;
				content:"";
				height:100%;
				width:200%;
				position:absolute;
				left:0;
				top:0;
				z-index:-1;
			}
		}
	}
	@include respond('tab-port'){
		.inner-content {
			padding-left: 0;
		}
		.box-skew{
			.authincation-content{
				width: auto;
			}
		}
	}
	@include respond('phone-land'){
		.box-skew{
			.authincation-content{
				position: relative;
				transform: unset;
				top: unset;
				right: unset;
				margin: auto;
				display: block;
				width: 100%;

				&:after{
					width: 100%;
				}
			}
		}
		.box-skew,
		.box-skew1{
			height: 100%;
			min-height: 100%;
			padding: 50px;
		}
	}
	@include respond('phone'){
		.box-skew,
		.box-skew1{
			padding: 30px;
		}
		.box-skew .authincation-content{
			padding: 30px;
		}
	}
}